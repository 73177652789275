exports.components = {
  "component---src-components-cms-cms-blog-template-cms-blog-template-tsx": () => import("./../../../src/components/CMS/CMSBlogTemplate/CMSBlogTemplate.tsx" /* webpackChunkName: "component---src-components-cms-cms-blog-template-cms-blog-template-tsx" */),
  "component---src-components-cms-cms-case-study-template-cms-case-study-template-tsx": () => import("./../../../src/components/CMS/CMSCaseStudyTemplate/CMSCaseStudyTemplate.tsx" /* webpackChunkName: "component---src-components-cms-cms-case-study-template-cms-case-study-template-tsx" */),
  "component---src-components-cms-cms-job-template-cms-job-template-tsx": () => import("./../../../src/components/CMS/CMSJobTemplate/CMSJobTemplate.tsx" /* webpackChunkName: "component---src-components-cms-cms-job-template-cms-job-template-tsx" */),
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-about-us-index-tsx": () => import("./../../../src/pages/about-us/index.tsx" /* webpackChunkName: "component---src-pages-about-us-index-tsx" */),
  "component---src-pages-about-us-old-v-3-tsx": () => import("./../../../src/pages/about-us/old-v3.tsx" /* webpackChunkName: "component---src-pages-about-us-old-v-3-tsx" */),
  "component---src-pages-ads-index-tsx": () => import("./../../../src/pages/ads/index.tsx" /* webpackChunkName: "component---src-pages-ads-index-tsx" */),
  "component---src-pages-ai-product-index-tsx": () => import("./../../../src/pages/ai-product/index.tsx" /* webpackChunkName: "component---src-pages-ai-product-index-tsx" */),
  "component---src-pages-application-academia-confirmation-index-tsx": () => import("./../../../src/pages/application/academia/confirmation/index.tsx" /* webpackChunkName: "component---src-pages-application-academia-confirmation-index-tsx" */),
  "component---src-pages-application-academia-contact-index-tsx": () => import("./../../../src/pages/application/academia/contact/index.tsx" /* webpackChunkName: "component---src-pages-application-academia-contact-index-tsx" */),
  "component---src-pages-application-academia-index-tsx": () => import("./../../../src/pages/application/academia/index.tsx" /* webpackChunkName: "component---src-pages-application-academia-index-tsx" */),
  "component---src-pages-application-academia-summary-index-tsx": () => import("./../../../src/pages/application/academia/summary/index.tsx" /* webpackChunkName: "component---src-pages-application-academia-summary-index-tsx" */),
  "component---src-pages-application-academia-team-index-tsx": () => import("./../../../src/pages/application/academia/team/index.tsx" /* webpackChunkName: "component---src-pages-application-academia-team-index-tsx" */),
  "component---src-pages-application-academia-use-index-tsx": () => import("./../../../src/pages/application/academia/use/index.tsx" /* webpackChunkName: "component---src-pages-application-academia-use-index-tsx" */),
  "component---src-pages-application-institution-confirmation-index-tsx": () => import("./../../../src/pages/application/institution/confirmation/index.tsx" /* webpackChunkName: "component---src-pages-application-institution-confirmation-index-tsx" */),
  "component---src-pages-application-institution-contact-index-tsx": () => import("./../../../src/pages/application/institution/contact/index.tsx" /* webpackChunkName: "component---src-pages-application-institution-contact-index-tsx" */),
  "component---src-pages-application-institution-details-index-tsx": () => import("./../../../src/pages/application/institution/details/index.tsx" /* webpackChunkName: "component---src-pages-application-institution-details-index-tsx" */),
  "component---src-pages-application-institution-index-tsx": () => import("./../../../src/pages/application/institution/index.tsx" /* webpackChunkName: "component---src-pages-application-institution-index-tsx" */),
  "component---src-pages-application-institution-summary-index-tsx": () => import("./../../../src/pages/application/institution/summary/index.tsx" /* webpackChunkName: "component---src-pages-application-institution-summary-index-tsx" */),
  "component---src-pages-application-institution-use-index-tsx": () => import("./../../../src/pages/application/institution/use/index.tsx" /* webpackChunkName: "component---src-pages-application-institution-use-index-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-blog-layout-blog-list-blog-list-tsx": () => import("./../../../src/pages/blog/_layout/BlogList/BlogList.tsx" /* webpackChunkName: "component---src-pages-blog-layout-blog-list-blog-list-tsx" */),
  "component---src-pages-blog-layout-blogs-hero-tsx": () => import("./../../../src/pages/blog/_layout/BlogsHero.tsx" /* webpackChunkName: "component---src-pages-blog-layout-blogs-hero-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-case-studies-components-case-hero-tsx": () => import("./../../../src/pages/case-studies/components/CaseHero.tsx" /* webpackChunkName: "component---src-pages-case-studies-components-case-hero-tsx" */),
  "component---src-pages-case-studies-components-case-testimonials-tsx": () => import("./../../../src/pages/case-studies/components/CaseTestimonials.tsx" /* webpackChunkName: "component---src-pages-case-studies-components-case-testimonials-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-comparisons-excel-index-tsx": () => import("./../../../src/pages/comparisons/excel/index.tsx" /* webpackChunkName: "component---src-pages-comparisons-excel-index-tsx" */),
  "component---src-pages-comparisons-freezerpro-index-tsx": () => import("./../../../src/pages/comparisons/freezerpro/index.tsx" /* webpackChunkName: "component---src-pages-comparisons-freezerpro-index-tsx" */),
  "component---src-pages-comparisons-labarchives-index-tsx": () => import("./../../../src/pages/comparisons/labarchives/index.tsx" /* webpackChunkName: "component---src-pages-comparisons-labarchives-index-tsx" */),
  "component---src-pages-comparisons-quartzy-index-tsx": () => import("./../../../src/pages/comparisons/quartzy/index.tsx" /* webpackChunkName: "component---src-pages-comparisons-quartzy-index-tsx" */),
  "component---src-pages-contact-us-index-tsx": () => import("./../../../src/pages/contact-us/index.tsx" /* webpackChunkName: "component---src-pages-contact-us-index-tsx" */),
  "component---src-pages-demo-conference-bpi-tsx": () => import("./../../../src/pages/demo/conference/bpi.tsx" /* webpackChunkName: "component---src-pages-demo-conference-bpi-tsx" */),
  "component---src-pages-demo-conference-isscr-2023-tsx": () => import("./../../../src/pages/demo/conference/isscr2023.tsx" /* webpackChunkName: "component---src-pages-demo-conference-isscr-2023-tsx" */),
  "component---src-pages-demo-conference-lse-2023-tsx": () => import("./../../../src/pages/demo/conference/lse2023.tsx" /* webpackChunkName: "component---src-pages-demo-conference-lse-2023-tsx" */),
  "component---src-pages-demo-conference-slas-2025-tsx": () => import("./../../../src/pages/demo/conference/slas2025.tsx" /* webpackChunkName: "component---src-pages-demo-conference-slas-2025-tsx" */),
  "component---src-pages-demo-index-tsx": () => import("./../../../src/pages/demo/index.tsx" /* webpackChunkName: "component---src-pages-demo-index-tsx" */),
  "component---src-pages-dpa-index-tsx": () => import("./../../../src/pages/dpa/index.tsx" /* webpackChunkName: "component---src-pages-dpa-index-tsx" */),
  "component---src-pages-eln-demo-index-tsx": () => import("./../../../src/pages/eln-demo/index.tsx" /* webpackChunkName: "component---src-pages-eln-demo-index-tsx" */),
  "component---src-pages-eln-software-index-tsx": () => import("./../../../src/pages/eln-software/index.tsx" /* webpackChunkName: "component---src-pages-eln-software-index-tsx" */),
  "component---src-pages-feature-lab-protocol-index-tsx": () => import("./../../../src/pages/feature/lab-protocol/index.tsx" /* webpackChunkName: "component---src-pages-feature-lab-protocol-index-tsx" */),
  "component---src-pages-feature-live-editing-index-tsx": () => import("./../../../src/pages/feature/live-editing/index.tsx" /* webpackChunkName: "component---src-pages-feature-live-editing-index-tsx" */),
  "component---src-pages-freezer-demo-index-tsx": () => import("./../../../src/pages/freezer-demo/index.tsx" /* webpackChunkName: "component---src-pages-freezer-demo-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-industry-academic-labs-index-tsx": () => import("./../../../src/pages/industry/academic-labs/index.tsx" /* webpackChunkName: "component---src-pages-industry-academic-labs-index-tsx" */),
  "component---src-pages-industry-non-profit-index-tsx": () => import("./../../../src/pages/industry/non-profit/index.tsx" /* webpackChunkName: "component---src-pages-industry-non-profit-index-tsx" */),
  "component---src-pages-industry-startups-index-tsx": () => import("./../../../src/pages/industry/startups/index.tsx" /* webpackChunkName: "component---src-pages-industry-startups-index-tsx" */),
  "component---src-pages-landingpagev-2-components-hero-v-3-index-tsx": () => import("./../../../src/pages/landingpagev2/_components/_HeroV3/index.tsx" /* webpackChunkName: "component---src-pages-landingpagev-2-components-hero-v-3-index-tsx" */),
  "component---src-pages-legacy-lims-index-tsx": () => import("./../../../src/pages/legacy-lims/index.tsx" /* webpackChunkName: "component---src-pages-legacy-lims-index-tsx" */),
  "component---src-pages-lims-software-index-tsx": () => import("./../../../src/pages/lims-software/index.tsx" /* webpackChunkName: "component---src-pages-lims-software-index-tsx" */),
  "component---src-pages-msa-index-tsx": () => import("./../../../src/pages/msa/index.tsx" /* webpackChunkName: "component---src-pages-msa-index-tsx" */),
  "component---src-pages-pricing-common-academia-tsx": () => import("./../../../src/pages/pricing/common/Academia.tsx" /* webpackChunkName: "component---src-pages-pricing-common-academia-tsx" */),
  "component---src-pages-pricing-common-faq-tsx": () => import("./../../../src/pages/pricing/common/FAQ.tsx" /* webpackChunkName: "component---src-pages-pricing-common-faq-tsx" */),
  "component---src-pages-pricing-common-icon-tsx": () => import("./../../../src/pages/pricing/common/Icon.tsx" /* webpackChunkName: "component---src-pages-pricing-common-icon-tsx" */),
  "component---src-pages-pricing-common-institution-tsx": () => import("./../../../src/pages/pricing/common/Institution.tsx" /* webpackChunkName: "component---src-pages-pricing-common-institution-tsx" */),
  "component---src-pages-pricing-common-pricing-card-tsx": () => import("./../../../src/pages/pricing/common/PricingCard.tsx" /* webpackChunkName: "component---src-pages-pricing-common-pricing-card-tsx" */),
  "component---src-pages-pricing-index-tsx": () => import("./../../../src/pages/pricing/index.tsx" /* webpackChunkName: "component---src-pages-pricing-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-private-ai-beta-index-tsx": () => import("./../../../src/pages/private/ai-beta/index.tsx" /* webpackChunkName: "component---src-pages-private-ai-beta-index-tsx" */),
  "component---src-pages-product-lab-inventory-management-software-index-tsx": () => import("./../../../src/pages/product/lab-inventory-management-software/index.tsx" /* webpackChunkName: "component---src-pages-product-lab-inventory-management-software-index-tsx" */),
  "component---src-pages-product-legacy-eln-software-index-tsx": () => import("./../../../src/pages/product/legacy-eln-software/index.tsx" /* webpackChunkName: "component---src-pages-product-legacy-eln-software-index-tsx" */),
  "component---src-pages-product-primer-design-index-tsx": () => import("./../../../src/pages/product/primer-design/index.tsx" /* webpackChunkName: "component---src-pages-product-primer-design-index-tsx" */),
  "component---src-pages-products-alerts-index-tsx": () => import("./../../../src/pages/products/alerts/index.tsx" /* webpackChunkName: "component---src-pages-products-alerts-index-tsx" */),
  "component---src-pages-products-equipments-index-tsx": () => import("./../../../src/pages/products/equipments/index.tsx" /* webpackChunkName: "component---src-pages-products-equipments-index-tsx" */),
  "component---src-pages-products-experiments-index-tsx": () => import("./../../../src/pages/products/experiments/index.tsx" /* webpackChunkName: "component---src-pages-products-experiments-index-tsx" */),
  "component---src-pages-products-integrations-components-integrations-hero-tsx": () => import("./../../../src/pages/products/integrations/_components/IntegrationsHero.tsx" /* webpackChunkName: "component---src-pages-products-integrations-components-integrations-hero-tsx" */),
  "component---src-pages-products-integrations-index-tsx": () => import("./../../../src/pages/products/integrations/index.tsx" /* webpackChunkName: "component---src-pages-products-integrations-index-tsx" */),
  "component---src-pages-products-lab-consumables-components-lab-consumables-cards-tsx": () => import("./../../../src/pages/products/lab-consumables/_components/LabConsumablesCards.tsx" /* webpackChunkName: "component---src-pages-products-lab-consumables-components-lab-consumables-cards-tsx" */),
  "component---src-pages-products-lab-consumables-index-tsx": () => import("./../../../src/pages/products/lab-consumables/index.tsx" /* webpackChunkName: "component---src-pages-products-lab-consumables-index-tsx" */),
  "component---src-pages-products-lab-protocol-components-lab-protocol-hero-tsx": () => import("./../../../src/pages/products/lab-protocol/_components/LabProtocolHero.tsx" /* webpackChunkName: "component---src-pages-products-lab-protocol-components-lab-protocol-hero-tsx" */),
  "component---src-pages-products-lab-protocol-index-tsx": () => import("./../../../src/pages/products/lab-protocol/index.tsx" /* webpackChunkName: "component---src-pages-products-lab-protocol-index-tsx" */),
  "component---src-pages-products-orders-index-tsx": () => import("./../../../src/pages/products/orders/index.tsx" /* webpackChunkName: "component---src-pages-products-orders-index-tsx" */),
  "component---src-pages-products-reports-index-tsx": () => import("./../../../src/pages/products/reports/index.tsx" /* webpackChunkName: "component---src-pages-products-reports-index-tsx" */),
  "component---src-pages-products-security-index-tsx": () => import("./../../../src/pages/products/security/index.tsx" /* webpackChunkName: "component---src-pages-products-security-index-tsx" */),
  "component---src-pages-products-version-control-index-tsx": () => import("./../../../src/pages/products/version-control/index.tsx" /* webpackChunkName: "component---src-pages-products-version-control-index-tsx" */),
  "component---src-pages-products-virtual-freezers-components-title-with-image-index-tsx": () => import("./../../../src/pages/products/virtual-freezers/_components/_TitleWithImage/index.tsx" /* webpackChunkName: "component---src-pages-products-virtual-freezers-components-title-with-image-index-tsx" */),
  "component---src-pages-products-virtual-freezers-index-tsx": () => import("./../../../src/pages/products/virtual-freezers/index.tsx" /* webpackChunkName: "component---src-pages-products-virtual-freezers-index-tsx" */),
  "component---src-pages-professional-services-index-tsx": () => import("./../../../src/pages/professional-services/index.tsx" /* webpackChunkName: "component---src-pages-professional-services-index-tsx" */),
  "component---src-pages-r-index-tsx": () => import("./../../../src/pages/r/index.tsx" /* webpackChunkName: "component---src-pages-r-index-tsx" */),
  "component---src-pages-solutions-academia-index-tsx": () => import("./../../../src/pages/solutions/academia/index.tsx" /* webpackChunkName: "component---src-pages-solutions-academia-index-tsx" */),
  "component---src-pages-solutions-antibodies-index-tsx": () => import("./../../../src/pages/solutions/antibodies/index.tsx" /* webpackChunkName: "component---src-pages-solutions-antibodies-index-tsx" */),
  "component---src-pages-solutions-biomanufacturers-index-tsx": () => import("./../../../src/pages/solutions/biomanufacturers/index.tsx" /* webpackChunkName: "component---src-pages-solutions-biomanufacturers-index-tsx" */),
  "component---src-pages-solutions-biopharma-index-tsx": () => import("./../../../src/pages/solutions/biopharma/index.tsx" /* webpackChunkName: "component---src-pages-solutions-biopharma-index-tsx" */),
  "component---src-pages-solutions-biopharmaceutical-index-tsx": () => import("./../../../src/pages/solutions/biopharmaceutical/index.tsx" /* webpackChunkName: "component---src-pages-solutions-biopharmaceutical-index-tsx" */),
  "component---src-pages-solutions-biorepositories-index-tsx": () => import("./../../../src/pages/solutions/biorepositories/index.tsx" /* webpackChunkName: "component---src-pages-solutions-biorepositories-index-tsx" */),
  "component---src-pages-solutions-cell-therapy-index-tsx": () => import("./../../../src/pages/solutions/cell-therapy/index.tsx" /* webpackChunkName: "component---src-pages-solutions-cell-therapy-index-tsx" */),
  "component---src-pages-solutions-chemistry-index-tsx": () => import("./../../../src/pages/solutions/chemistry/index.tsx" /* webpackChunkName: "component---src-pages-solutions-chemistry-index-tsx" */),
  "component---src-pages-solutions-clinical-research-index-tsx": () => import("./../../../src/pages/solutions/clinical-research/index.tsx" /* webpackChunkName: "component---src-pages-solutions-clinical-research-index-tsx" */),
  "component---src-pages-solutions-contract-services-index-tsx": () => import("./../../../src/pages/solutions/contract-services/index.tsx" /* webpackChunkName: "component---src-pages-solutions-contract-services-index-tsx" */),
  "component---src-pages-solutions-cro-consulting-index-tsx": () => import("./../../../src/pages/solutions/cro-consulting/index.tsx" /* webpackChunkName: "component---src-pages-solutions-cro-consulting-index-tsx" */),
  "component---src-pages-solutions-industrial-biotech-index-tsx": () => import("./../../../src/pages/solutions/industrial-biotech/index.tsx" /* webpackChunkName: "component---src-pages-solutions-industrial-biotech-index-tsx" */),
  "component---src-pages-solutions-rna-therapy-index-tsx": () => import("./../../../src/pages/solutions/rna-therapy/index.tsx" /* webpackChunkName: "component---src-pages-solutions-rna-therapy-index-tsx" */),
  "component---src-pages-solutions-startups-index-tsx": () => import("./../../../src/pages/solutions/startups/index.tsx" /* webpackChunkName: "component---src-pages-solutions-startups-index-tsx" */),
  "component---src-pages-system-status-index-tsx": () => import("./../../../src/pages/system-status/index.tsx" /* webpackChunkName: "component---src-pages-system-status-index-tsx" */),
  "component---src-pages-team-lab-manager-index-tsx": () => import("./../../../src/pages/team/lab-manager/index.tsx" /* webpackChunkName: "component---src-pages-team-lab-manager-index-tsx" */),
  "component---src-pages-team-life-science-project-management-index-tsx": () => import("./../../../src/pages/team/life-science-project-management/index.tsx" /* webpackChunkName: "component---src-pages-team-life-science-project-management-index-tsx" */),
  "component---src-pages-team-research-and-development-index-tsx": () => import("./../../../src/pages/team/research-and-development/index.tsx" /* webpackChunkName: "component---src-pages-team-research-and-development-index-tsx" */),
  "component---src-pages-terms-of-service-index-tsx": () => import("./../../../src/pages/terms-of-service/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-index-tsx" */)
}

